* {
  box-sizing: border-box;
}

body {
  font-family: "NoirProMedium";
  font-weight: 500;
}

.app-loading {
  width: 100%;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
}
